/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

header {
  background-color: transparent;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 100px;
  padding-right: 100px;
}

.fa-search {
  color: #0ad05b;
  color: white;
  padding-top: 14px;
  font-size: 32px;
  outline: none;
}
.needEndpoint {
  color: #0ad05b;
  width: 100%;
  margin: auto;
  margin-top: 60px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 4rem;
  font-weight: 100;
  font-style: oblique;
}
#box {
  border: 2px solid white;

  margin-left: 20px;
  margin-top: 110px;
}
#autocomple {
  color: white;
  margin-left: 50px;
}
#results {
  margin-left: 80px;
  margin-right: 80px;
}
/* Thick red border */
hr {
  border: 1px solid green;
}
.form-control {
  background: transparent;
  color: white !important;
}
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

.container {
  display: flex;
}

.ACcontainer {
  color: #0ad05b;
  background: #061621;
  z-index: 200;
  width: 100%;
  position: absolute;
  text-align: center;
  margin-top: 60px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 100;
  font-style: oblique;
  opacity: 0.95;
}
body {
  background: #061621; /* fallback for old browsers */
  color: white;
  font-family: "Lexend Deca", sans-serif;
  font-weight: 100;
}
.grid-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto auto auto auto;
}
.MovieCard {
  padding: 15px;
  border-radius: 10px;
  background-color: #21313c;
  margin-bottom: 30px;
  max-height: 600px;
  overflow: auto;
  text-align: center;
  transition: all 0.4s ease-in-out;
}
.column {
  float: left;
  width: 100%;
  padding: 0 10px;
}
.MovieCard:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 200px 50px darkgreen;
  box-shadow: 0px 0px 200px 50px rgba(6, 22, 33, 0.7);
}
h3 {
  color: #0ad05b;
}
h5 {
  font-size: 17px;
}
p {
  font-size: 15px;
}
.MovieCard h3 {
  margin-bottom: 15px;
}
.MovieCard p {
  margin-top: 15px;
}
.color-gray {
  color: #9fa1a2;
}
.img-poster {
  margin: 15px 0 25px;
  max-height: 250px;
  width: auto;
}
